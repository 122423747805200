import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../assets/css/style.css";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import "../assets/css/meanmenu.min.css";
import "../assets/css/responsive.css";

const drawerWidth = 240;

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [modal1, setModal1] = useState(false);
  const theme = useTheme();
  const drawerWidth = 240;
  const [isOpen, setIsOpen, isOpenref] = useState(true);
  const [loginCheck, setloginCheck] = useState(false);
  const goto1 = () => setModal1(!modal1);
  const [dropstatus, setdropstatus] = useState(false);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const dropdowns = async () => {
    if (dropstatus == true) {
      setdropstatus(false);
    } else {
      setdropstatus(true);
    }
  };
  const zIndex1 = 2000000;
  useEffect(() => {
    isOpenref.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken != null) {
      // get_check();
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const logout = async () => {
    await removeAuthorization();
    await removeAuthToken();
    localStorage.clear();
    navigate("/login");
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Set a timer to switch from the loader to the main content after 2 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

    const get_check = async () => {
    var datas = {
      apiUrl: apiService.check_auth,
      payload: {
        token: localStorage.getItem("user_token"),
      },
    };
    var response = await postMethod(datas);
    if (!response.status) {
      logout();
    }
    else
    {
      setloginCheck(true);
    }
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#121418", color: "#fff" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <div className="landing-header1 none_img">
          <img
            src={require("../assets/images/Logo.png")}
            alt="logo image"
            className="logo-img "
          />
        </div>
      </Typography>
      <Divider />
      <List>
        {loginCheck == true ? (
          <>
            {/* <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/" className="drawer_list_item">
                  <span className="land_header_leftmenus">Home</span>
                </Link>
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/dashboard" className="drawer_list_item">
                  <span className="land_header_leftmenus">Dashboard</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/staking" className="drawer_list_item">
                  <span className="land_header_leftmenus">Staking</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/swap" className="drawer_list_item">
                  <span className="land_header_leftmenus">Swap</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/p2p" className="drawer_list_item">
                  <span className="land_header_leftmenus">P2P</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
            <Link to="/internaltransfer" className="drawer_list_item">
              <span className="land_header_leftmenus">Internal Transfer</span>
            </Link>
          </ListItemButton>
        </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/withdraw" className="drawer_list_item">
                  <span className="land_header_leftmenus">Withdrawal</span>
                </Link>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/create-2fa" className="drawer_list_item">
                  <span className="land_header_leftmenus">Security</span>
                </Link>
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/treeview" className="drawer_list_item">
                  <span className="land_header_leftmenus">My Tree</span>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/profithistory" className="drawer_list_item">
                  <span className="land_header_leftmenus">Profit</span>
                </Link>
              </ListItemButton>
            </ListItem>

            {/* <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/leaderboard" className="drawer_list_item">
                  <span className="land_header_leftmenus">Leader Board</span>
                </Link>
              </ListItemButton>
            </ListItem> */}

            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/settings" className="drawer_list_item">
                  <span className="land_header_leftmenus">Security-Settings</span>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="https://baby-walrus.com/" className="drawer_list_item" target="_blank">
                  <span className="land_header_leftmenus">Baby Walrus</span>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/deposit" className="drawer_list_deposit">
                  <span className="land_header_leftmenus">
                    <img
                      src={require("../assets/images/dash_icon/download_icon.png")}
                    />
                    Deposit
                  </span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link onClick={logout} className="drawer_list_deposit">
                  <span className="land_header_leftmenus">
                    <img src={require("../assets/images/icons/logout.png")} />
                    Logout
                  </span>
                </Link>
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/" className="drawer_list_item">
                  <span className="land_header_leftmenus">Home</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="#about" className="drawer_list_item">
                  <span className="land_header_leftmenus">About</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/staking" className="drawer_list_item">
                  <span className="land_header_leftmenus">Features</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="" className="drawer_list_item">
                  <span className="land_header_leftmenus">Packages</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/p2p" className="drawer_list_item">
                  <span className="land_header_leftmenus">Community</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/deposit" className="drawer_list_login">
                  <span className="land_header_leftmenus">Login</span>
                </Link>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center", color: "#fff" }}>
                <Link to="/deposit" className="drawer_list_deposit">
                  <span className="land_header_leftmenus">Register</span>
                </Link>
              </ListItemButton>
            </ListItem> */}
          </>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <div className="drawer-head">
            {loading ? (
              <div className="loader-wrap">
                <div className="preloader">
                  <div className="preloader-close">x</div>
                  <div id="handle-preloader" className="handle-preloader">
                    <div className="animation-preloader">
                      <div className="txt-loading">
                        <span
                          data-text-preloader="L"
                          className="letters-loading"
                        >
                          L
                        </span>
                        <span
                          data-text-preloader="O"
                          className="letters-loading"
                        >
                          O
                        </span>
                        <span
                          data-text-preloader="A"
                          className="letters-loading"
                        >
                          A
                        </span>
                        <span
                          data-text-preloader="D"
                          className="letters-loading"
                        >
                          D
                        </span>
                        <span
                          data-text-preloader="I"
                          className="letters-loading"
                        >
                          I
                        </span>
                        <span
                          data-text-preloader="N"
                          className="letters-loading"
                        >
                          N
                        </span>
                        <span
                          data-text-preloader="G"
                          className="letters-loading"
                        >
                          G
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <>
              <div className="header-res-menu">
                <div className="landing-header1 ">
                  <Link to="/">
                    <img
                      src={require("../assets/images/Logo.png")}
                      alt="logo"
                      className="logo-img landing_responsive_img"
                    />
                  </Link>
                </div>
              </div>
              <div className="header-right-menu">
                <div className="header_res_flx">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    className="p-0 "
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { xs: "block", lg: "none" } }}
                  >
                    <MenuIcon className="mt-2" />
                  </IconButton>
                </div>
              </div>
            </>
          </div>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", lg: "block" } }}
          >
            <div className="header-res-menu">
              <div className="landing-header1">
                <Link to="/">
                  <img
                    src={require("../assets/images/Logo.png")}
                    alt="logo"
                    className="logo-img-1"
                  />
                </Link>
              </div>
              <div className="landing-header2">
                {loginCheck == true ? (
                  <>
                    
                    <Link to="/dashboard">
                      <span className="land_header_leftmenus">Dashboard</span>
                    </Link>
                    <Link to="/staking">
                      <span className="land_header_leftmenus">Staking</span>
                    </Link>
                    <Link to="/swap">
                      <span className="land_header_leftmenus">Swap</span>
                    </Link>
                    <Link to="/p2p">
                      <span className="land_header_leftmenus">P2P</span>
                    </Link>
                    <Link to="/internaltransfer">
                      <span className="land_header_leftmenus">Internal Transfer</span>
                    </Link>
                    <Link to="/withdraw">
                      <span className="land_header_leftmenus">Withdrawal</span>
                    </Link>
                    {/* <Link to="/create-2fa">
                      <span className="land_header_leftmenus">Security</span>
                    </Link> */}
                    <Link to="/treeview">
                      <span className="land_header_leftmenus">My Tree</span>
                    </Link>
                    <Link to="/profithistory">
                      <span className="land_header_leftmenus">Profit</span>
                    </Link>

                    {/* <Link to="/leaderboard">
                      <span className="land_header_leftmenus">Leader Board</span>
                    </Link> */}

                    <Link to="/settings">
                      <span className="land_header_leftmenus">Security-Settings</span>
                    </Link>

                    <Link to="https://baby-walrus.com/" target="_blank">
                      <span className="land_header_leftmenus">Baby Walrus</span>
                    </Link>
                  </>
                ) : (
                  <>
                    <a href="/">
                      <span className="land_header_leftmenus">Home</span>
                    </a>
                    <a href="#features">
                      <span className="land_header_leftmenus">Features</span>
                    </a>
                    <a href="#about">
                      <span className="land_header_leftmenus">About</span>
                    </a>

                    <a href="#packages">
                      <span className="land_header_leftmenus">Packages</span>
                    </a>
                    <a href="#community">
                      <span className="land_header_leftmenus">Community</span>
                    </a>
                  </>
                )}
              </div>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {loginCheck == true ? (
                  <>
                    <>
                      <div className="header_new_main ">
                        <div className="outer-box d-flex align-items-center flex-wrap outer_box_2">
                          <span className="land_header_leftmenus ">
                            {/* <img
                              src={require("../assets/images/dash_icon/notification_icon.png")}
                            /> */}
                          </span>
                          <span className="land_header_leftmenus deposit_button">
                            <Link to="/deposit">
                              {" "}
                              <img
                                src={require("../assets/images/dash_icon/download_icon.png")}
                              />
                              Deposit
                            </Link>
                          </span>

                          <span
                            className="land_header_leftmenus deposit_button"
                            onClick={logout}
                          >
                            <a>
                              {" "}
                              <img
                                src={require("../assets/images/icons/logout.png")}
                              />
                              Logout
                            </a>
                          </span>
                        </div>
                      </div>
                    </>
                  </>
                ) : (
                  <>
                    <>
                      <div className="header_new_main ">
                        <div className="outer-box d-flex align-items-center flex-wrap">
                          <div className="main-header_buttons">
                            <a
                              href="login"
                              className="template-btn btn-style-two"
                            >
                              <span className="btn-wrap">
                                <span className="text-one">Login</span>
                                <span className="text-two">Login</span>
                              </span>
                            </a>
                            {/* <a
                              href="register"
                              className="template-btn btn-style-one"
                            >
                              <span className="btn-wrap">
                                <span className="text-one">Register</span>
                                <span className="text-two">Register</span>
                              </span>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </Box>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
