import React, { useEffect } from "react";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import InnerHeader from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import {
  removeAuthToken
} from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import Chart from "./Chart";

const Dashboard = () => {
  const navigate = useNavigate();
  const [splitURL, setsplitURL] = useState("");
  const [UserDetails, setUserDetails] = useState("");
  const [refUrls, setRefURLS] = useState("");
  const [referral, setReferral] = useState([]);
  const [referralUser, setreferralUser] = useState(0);
  const [token, setToken] = useState(false);
  const [dashboradData, setdashboradData] = useState("");
  const [rightUsers, setrightUsers] = useState([]);
  const [leftUsers, setleftUsers] = useState([]);
  const [leftCount, setleftCount] = useState(0);
  const [rightCount, setrightCount] = useState(0);
  const [allIncome, setallIncome] = useState(0);
  const [allAssets, setallAssets] = useState(0);
  const [userRank,setuserRank] = useState("");
  const [remainingAmount, setremainingAmount] = useState(0);
  const [allProfit, setallProfit] = useState(0);

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getProfile();
    // getRefUser();
    getDashboardDetails();
    getUserRank();
  }, [0]);


  const getProfile = async () => {
    try {
      var datas = {
        apiUrl: apiService.getuserdetail,
      };
      var responce = await getMethod(datas);
      if (responce) {
        setUserDetails(responce.data);
        var currURL = window.location.href.split("dashboard")[0];
        setsplitURL(currURL);
        var leftUrl =
          currURL +
          "register?referral=" +
          responce.data.internalID +
          "&position=A";
        var rightUrl =
          currURL +
          "register?referral=" +
          responce.data.internalID +
          "&position=B";
        var obj = {
          left: leftUrl,
          right: rightUrl,
        };
        setRefURLS(obj);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.success("Please try  later!");
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };


  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Referral Link copied successfully");
  };

  const nav_link = async (link) => {
    window.open(link, "_blank")
  };

  // const getRefUser = async () => {
  //   var datas = {
  //     apiUrl: apiService.getReferral,
  //   };
  //   var responce = await getMethod(datas);
  //   setreferralUser(responce.data.length);
  //   var left_users = responce.data.filter((item) => item.position === "Left");
  //   var right_users = responce.data.filter((item) => item.position === "Right");
  // };

  const getUserRank = async () => {
    var datas = {
      apiUrl: apiService.get_user_rank,
    };
    var response = await getMethod(datas);
    console.log(response, "=-=-=-=rank response=-=-=");
    if (response.status) {
      setuserRank(response.rank_details);
    } else {
      setuserRank("");
    }
  };


  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 5;


  useEffect(() => {
    getReferralUser();
  }, [currentPage])


  // const getReferralUser = async () => {
  //   var datas = {
  //     apiUrl: apiService.getReferraluser,
  //     payload: {
  //       currentPage: currentPage + 1, // ReactPaginate uses 0-based index, so we add 1
  //       pageSize: pageSize,
  //     },
  //   };
  //   var response = await postMethod(datas);
  //   if (response.status) {
  //     setreferral(response.data);
  //     setTotalPages(response.totalPages);
  //   } else {
  //     setreferral([]);
  //   }
  // };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };


  const getDashboardDetails = async () => {
    try {
      var datas = {
        apiUrl: apiService.dashboard,
      };
      var response = await getMethod(datas);
      console.log(response, "=-=-=-=response=-=-=");
      if (response.status) {
        // setreferralUser(responce.data.length);

        // const Binary = response.data.BinaryIncome / 2;

        // const left = response.data.LeftIncome + Binary;
        // const right = response.data.RightIncome + Binary;

        // setleftside(left);
        // setrightside(right);


        if (response.data != null) {
          setdashboradData(response.data);
          //var allincome = +response.data.directIncome + +response.data.BinaryIncome + +response.data.dailyIncome;
          setallIncome(response.data.all_income);
          setallAssets(response.data.total_assets);
          let remaining_amount = (+response.data.stakingAmount * 3) - +response.data.all_income;
          remaining_amount = parseFloat(remaining_amount).toFixed(2);
          setremainingAmount(remaining_amount);
          setallProfit(response.data.all_profit);
        }
      } else {
        setdashboradData("");
      }

    } catch (error) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }

  };


  const getReferralUser = async () => {
    try {
      const data = {
        apiUrl: apiService.redis_treeDetails,
      };
      const resp = await getMethod(data);
      if (resp.status) {
        const responseData = resp.data;
        setReferral(responseData);

        const leftSideStrength = countUsers(responseData.leftUser);
        const rightSideStrength = countUsers(responseData.rightUser);

        setleftCount(leftSideStrength);
        setrightCount(rightSideStrength);


      }
    } catch (error) {
      console.error("Error fetching tree data", error);
    }
  };


  function countUsers(node) {
    if (node === null) {
      return 0;
    }

    // Count the current node, then recursively count the left and right children
    return 1 + countUsers(node.leftUser) + countUsers(node.rightUser);
  }

  return (
    <div className="dashboard_main">
      <div className="inner_header">
        <InnerHeader />
      </div>
      <section className="dashboard_content_section steps-one ">
        <div class="inner-container container">
          <div class="row  relative clearfix">
            <div className=""></div>
            {/* <div className="col-lg-12">
              <div className="username  d-flex justify-content-between">
                <div>User Name : {UserDetails.username}</div>
               
              </div>
            </div> */}


            <div className="col-9 row">
              <div className="col-12">
             
                {/* <div className="total_dash_card h1s d-flex justify-content-between">

                  <div>
                    <img
                      src={require("../images/total.png")}
                      className="total_balance d500"
                    />

                    <div className="total_dash_content">
                      <h4>Total Assets</h4>
                      {/* <p>

                        <span>
                          {dashboradData &&
                            (
                              allAssets
                            ).toFixed(2)}{" "}
                        </span>{" "}
                        USDT
                      </p> 

                      <p>

                      <span>
                        {dashboradData && allAssets ? (
                          "$ " +parseFloat(allAssets).toFixed(2)
                        ) : (
                          ""
                        )}

                        
                      </span>{" "}
                      </p>
                    </div>
                  </div>


                  <div className="total_dash_content">

                    <p>
                    <span>
                      Package: {dashboradData && (dashboradData.stakingAmount ? 
                        "$ "+ dashboradData.stakingAmount : "$ "+0) }{" "}
                    </span>
                    <span>
                      / Bal: {dashboradData &&
                        (dashboradData.stakingAmount ? (
                        "$ " +remainingAmount ) : ("$ " +0))}
                    </span>
                    </p>
                  </div>

                </div> */}

                <div className="chart-container">
                <div className="chart-section">
                <Chart />
                </div>

                {/* Text Section */}
                <div className="text-section">
                  <div className="text-line total-earnings">
                    Total Earnings: <span>{dashboradData && allProfit ? (
                          "$ " +parseFloat(allProfit).toFixed(2)
                        ) : (
                          ""
                        )}</span>
                  </div>
                  <div className="text-line balance">
                    Balance: <span>{dashboradData &&
                  (dashboradData.stakingAmount ? (
                  "$ " +remainingAmount ) : ("$ " +0))}</span>
                  </div>
                  <div className="text-line current-package">
                    Current Package: <span>{dashboradData && (dashboradData.stakingAmount ? 
                  "$ "+ dashboradData.stakingAmount : "$ "+0) }</span>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-6">
                <div className="total_dash_card">
                  <img
                    src={require("../images/Wallet.png")}
                    className="total_balance d500"
                  />

                  <div className="total_dash_content">
                    <h4>Smart wallet</h4>
                    <p>

                      <span>
                        {dashboradData &&
                          (
                            dashboradData.withdraw_wallet_wxdl
                          ).toFixed(2)}{" "}
                      </span>{" "}
                      WXDL
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="total_dash_card">
                  <img
                    src={require("../images/p2p.png")}
                    className="total_balance d500"
                  />

                  <div className="total_dash_content">
                    <h4>p2p wallet</h4>
                    {/* <p>
                      <span>
                        {dashboradData && dashboradData.p2p_wallet_wxdl.toFixed(2)}
                      </span>{" "}
                      WXDL
                    </p> */}
                    <p>
                      <span>
                        {dashboradData && dashboradData.p2p_wallet_wxdl ? (
                          "$ "+parseFloat(dashboradData.p2p_wallet_wxdl).toFixed(2)
                        ) : ("")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>


            </div>
            <div className="col-3 p-0 rank-user">
              <div className="ranking">
                <h4>{UserDetails.username}</h4>
                <h5>{UserDetails.internalID}</h5>
                {/* {userRank != "" ? (
                <h5>{userRank.name}</h5>
                ) : ("")} */}

               {userRank == "" ? (
                <div className="total_dash_card1">
                  <p className="m-0">
                    Rank
                  </p>

                </div>
               ) : (
                <div className="total_dash_card1">
                {/* <p className="m-0">
                  {userRank.name}
                </p> */}
                <img src={userRank.logo} />

              </div>
               )}
              </div>
            </div>



            <div className="col-6">
              <Link to="/teamA">
                <div className="total_dash_card barder-theme">
                  <img
                    src={require("../images/user.png")}
                    className="total_balance d500"
                  />

                  <div className="total_dash_content">
                    <h4>A Team</h4>
                    {/* <p>
                      <span>
                        {leftCount} / {UserDetails && UserDetails.teamA.toFixed(2)}
                      </span>{" "}
                      WXDL
                    </p> */}
                     <p>
                      <span>
                        {leftCount} / {UserDetails && UserDetails.teamA ? (
                          "$ "+UserDetails.teamA
                        ) : ("")}
                      </span>
                    </p>
                  </div>
                </div>
              </Link>

            </div>

           

            <div className="col-6">
              <Link to="/teamB">
                <div className="total_dash_card barder-theme">
                  <img
                    src={require("../images/user.png")}
                    className="total_balance d500"
                  />

                  <div className="total_dash_content">
                    <h4>B Team</h4>
                    {/* <p>
                      <span>
                        {rightCount} / {UserDetails && UserDetails.teamB.toFixed(2)}
                      </span>{" "}
                      WXDL
                    </p> */}
                     <p>
                      <span>
                        {rightCount} / {UserDetails && UserDetails.teamB ? (
                          "$ "+UserDetails.teamB
                        ) : ("")}
                      </span>
                    </p>
                  </div>
                </div>
              </Link>

            </div>

            <div className="col-12 ">
            {/* <gecko-coin-price-marquee-widget locale="en" dark-mode="true" coin-ids="" initial-currency="usd"></gecko-coin-price-marquee-widget> */}
            <gecko-coin-price-marquee-widget locale="en" coin-ids="" initial-currency="usd"></gecko-coin-price-marquee-widget>
            <br/>
            </div>
            
            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <Link to="/deposit" className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/deposit  1.png")}
                    />
                  </div>
                  <p>Deposit</p>
                </Link>
              </div>
            </div>
            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <Link to="/withdraw" className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/withdrawl.png")}
                    />
                  </div>
                  <p>Withdraw</p>
                </Link>
              </div>
            </div>
            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <Link to="/p2p" className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/transfer.png")}
                    />
                  </div>
                  <p>Transfer</p>
                </Link>
              </div>
            </div>
            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <Link to="/swap" className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/swap 1.png")}
                    />
                  </div>
                  <p>Swap</p>
                </Link>
              </div>
            </div>
            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <div className="total_dash_content text-center">
                <Link to="/xdlgiveaway" className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/give.png")}
                    />
                  </div>
                  <p>XDL Giveaway</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <div className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/buy.png")}
                    />
                  </div>
                  <p>USDT Store: {dashboradData && (dashboradData.wallet_usdt ? dashboradData.wallet_usdt : 0).toFixed(2)}</p>
                </div>
              </div>
            </div>

            <div className=" col-lg-2 col-4">
              <div className="total_dash_card justify-content-center p-3">
                <div className="total_dash_content text-center">
                <Link to="/leaderboard" className="total_dash_content text-center">
                  <div className="h1s">
                    <img
                      src={require("../images/leaderboard.png")}
                    />
                  </div>
                  <p>Leader Board</p>
                </Link>
                </div>
              </div>
            </div>

            <div className="col-12 ">
              <div className="dashboard_links row">
                <div className="left_referral_link_dash col-lg-4">
                  <h3>User ID</h3>
                  <p className="left_referral_link">
                    <input type="text" value={UserDetails.internalID} />
                    <i
                      class="ri-file-copy-line cursor-pointer"
                      onClick={() => copy(UserDetails.internalID)}
                    ></i>
                  </p>
                </div>
                <div className="left_referral_link_dash col-lg-4">
                  <h3>A Team Referral Link</h3>
                  <p className="left_referral_link">
                    <input
                      type="text"
                      value={refUrls.left}
                      // placeholder="https://WXDL/register?username=Mugesh&position="
                      // disabled
                      onClick={() => nav_link(refUrls.left)}
                      className="nav_link"
                    />
                    <i
                      class="ri-file-copy-line cursor-pointer"
                      onClick={() => copy(refUrls.left)}
                    ></i>
                  </p>
                </div>
                <div className="left_referral_link_dash col-lg-4">
                  <h3>B Team Referral Link</h3>
                  <p className="left_referral_link">
                    <input
                      type="text"
                      value={refUrls.right}
                    // placeholder="https://WXDL/register?username=Mugesh&position="
                     onClick={() => nav_link(refUrls.right)}
                      className="nav_link"
                    />
                    <i
                      class="ri-file-copy-line cursor-pointer"
                      onClick={() => copy(refUrls.right)}
                    ></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
};
export default Dashboard;
